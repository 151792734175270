import React, { useState, useEffect, useRef } from "react"
import toast from "react-hot-toast"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleReactValidator from "simple-react-validator"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import LinkButton from "../components/addon/button/linkButton"
import APIButton from "../components/addon/button/apiButton"
import Thankyou from '../components/addon/thankYou'
import { acceptOnlyNumbers, concealMobileNo } from "../service/helper"
import { handleHHLogin } from '../service/auth'
import USER_API from '../api/user'
import API from '../api/postings'
import useScript from "../service/useScript"

const ActivateAccount = (props) => {
    const metaTags = []
    const ogForm = {
        title: "",
        fullName: '',
        firstName: "",
        middleName: "",
        lastName: "",
        email: '',
        dob: "",
        mobile: '',
        aadharImg: '',
        newEmail: '',
        newContactNo: '',
    }
    const [form, setForm] = useState(ogForm)
    const [otp, setOTP] = useState("")
    const [step2, setStep2] = useState(false) // show hh, mobile, otp 
    const [step3, setStep3] = useState(false) // show complete form
    const [step4, setStep4] = useState(false) // show complete form
    const [loading, setLoading] = useState(false)
    const [otpLoading, setOTPLoading] = useState(false)
    const [emailOTP, setEmailOTP] = useState('')
    const [emailLoading, setEmailLoading] = useState(false)
    const [showEmailOtp, setShowEmailOtp] = useState(false)
    const [showAltOtp, setShowAltOtp] = useState(false)
    const [hhNumber, setHHNumber] = useState('')
    const [fileError, setFileError] = useState('')
    const [aadharImg, setAadharImg] = useState(null);
    const [checkbox, setCheckbox] = useState(null);
    const [, setUserFound] = useState(null)
    const [, forceUpdateForRegister] = useState();
    const [thankYou, setThankYou] = useState(false)

    const lockey = 'phone'
    const emailkey = 'email'

    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => <p className="field_error" > {message} </p>,
        validators: {
            checkAltMobile: {
                message: 'The :attribute cannot be same as mobile no.',
                rule: (val, params, validator) => {
                    if(val == localStorage.getItem(lockey)) {
                        return false
                    } else {
                        return val
                    }
                },
                messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                required: false  // optional
            },
            validateMobile: {
                message: 'The :attribute should be a valid mobile no.',
                rule: (val, params, validator) => {
                    if(parseInt(val) === 0 && val?.length >= 3 || val?.startsWith("000")) {
                        return false
                    } else {
                        return val
                    }
                },
                messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                required: false  // optional
            },
            checkAltEmail: {
                message: 'The :attribute cannot be same as email Id',
                rule: (val, params, validator) => {
                    if(val.toLowerCase() === localStorage.getItem(emailkey).toLowerCase()) {
                        return false
                    } else {
                        return val
                    }
                },
                messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                required: false  // optional
            },
        }
    })))

    const resetForm = () => {
        setStep2(false)
        setStep3(false)
        setStep4(false)
        setLoading(false)
        setForm(ogForm)
        setHHNumber('')
        setOTP('')
        setUserFound(null)
    }

    function calculateAge(dateString) {
        const from = dateString.split("/");
        const birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);
        const cur = new Date();
        const diff = cur - birthdateTimeStamp;
        // This is the difference in milliseconds
        const currentAge = Math.floor(diff/31557600000);
        return currentAge;
    }

    const onInputChange = (e) => {
        const { name, value } = e?.target
        if(name === 'newContactNo') {
            let val = acceptOnlyNumbers(value)
            setForm({
                ...form,
                [name]: val
            })
        } else {
            setForm({
                ...form,
                [name]: value
            })
        }
    }

    const handleAadharImg = async (e) => {
        let fileName =  e.target.value.toLowerCase()
        let file = e.target.files[0]
        if (file && fileName.includes('.jpg') 
            || file && fileName.includes('.png') 
            || file && fileName.includes('.pdf')
            || file && fileName.includes('.jpeg')) {
            setFileError("");
            setAadharImg(file)
        } else {
            setFileError("Please upload your Aadhar Card Img in .jpg or .png or pdf format")
        }
    }

    const onResendOTP = (mobile_no, alt) => {
        if(mobile_no?.length === 10 || form?.mobile?.length === 10) {
            setOTPLoading(true)
            USER_API.sendOTP({ mobileNo : mobile_no ? mobile_no : form?.mobile, "otpFor": "activate" })
            .then(response => {
                toast.success("OTP sucessfully sent on mobile no.")
                setOTPLoading(false)
                setStep3(true)
                if(alt) setShowAltOtp(true)
            })
            .catch(err => {
                console.log(err)
                toast.error("Something went wrong. Please try again later")
                setOTPLoading(false)
            })
        } else {
            if(mobile_no?.length === 0 || form?.mobile?.length === 0) {
                toast.error("Provide Mobile No. form mobile validation.")
            } else {
                toast.error("Mobile No. requires 10 digit number.")
            }
        }
    }

    const sendOTP = () => {
        onResendOTP()
    }

    const handleVerifyHHNumber = (e) => {
        e.preventDefault();
        const result = formValidator.current.allValid()
        if(!result) {
            formValidator.current.showMessages()
            forceUpdateForRegister(1)
        }
        if(result && hhNumber?.length) {
            validateUserHHNumber()
            formValidator.current.hideMessages()
        }
    }

    const sendOTPForAltPhone = () => {
        if(form?.newContactNo?.length && formValidator?.current?.fields?.newContactNo) {
            onResendOTP(form?.newContactNo, true)
        } else {
            if(form?.newContactNo?.length === 0) {
                toast.error("Provide New contact no for verification")
            } else {
                toast.error("Provide New contact no with 10 digit")
            }
        }
    }

    const sendOTPForEmail = () => {
        if(form?.newEmail?.length && formValidator?.current?.fields?.newEmail) {
            setEmailLoading(true)
            USER_API.sendEmailOTP({ email_id : form?.newEmail })
            .then(response => {
                toast.success("OTP sucessfully sent on Email Id")
                setEmailLoading(false)
                setShowEmailOtp(true)
            })
            .catch(err => {
                console.log(err)
                toast.error("Something went wrong. Please try again later")
                setEmailLoading(false)
                setShowEmailOtp(false)
            })
        } else {
            if(form?.newEmail?.length === 0) {
                toast.error("Provide email id. for email verification")   
            } else if(form?.newEmail.toLowerCase() === localStorage.getItem(emailkey).toLowerCase()) {
                toast.error("The new email cannot be same as email Id") 
            } else {
                toast.error("Provide valid emial id.")
            }
        }
    }

    const handleOTPVerify = async () => {
        const result = formValidator.current.allValid()
        if(!result) {
            formValidator.current.showMessages()
            forceUpdateForRegister(2)
            return false
        }
        if(result && otp?.length) {
            setLoading(true)
            formValidator.current.hideMessages()
            USER_API.verifyOTP(otp)
            .then(res => {
                setLoading(false)
                if(res === true) {
                    setStep4(true)
                    setOTP('')
                    return true
                } else {
                    toast.error("Invalid OTP")
                    return false
                }
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                toast.error("Something went wrong. Please try again later")
                return false
            })
        }
    }

    const handleSubmit = async () => {
        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
            forceUpdateForRegister(3)
        } 
        if(result && fileError === '') {
            if(form?.newEmail?.length && emailOTP?.length === 0 && result) {
                toast.error("Verify new email id before proceeding.")
                return
            }
            if(form?.newContactNo?.length && otp?.length === 0 && result) {
                toast.error("Verify new contact no. before proceeding.")
                return
            }
            if (checkbox && result) {
                let payload = {
                    "webform_id": "activate_user",
                    "title": form?.title,
                    "name": form?.firstName + ' '  + form?.lastName,
                    "gender": form?.gender,
                    "hh_number": hhNumber,
                    "mobile_number" : form?.mobile,
                    "email_id" : form?.email,
                    "dob" : form?.dob,
                    "new_email_id" : form?.newEmail,
                    "new_mobile_number" : form?.newContactNo
                }
                // verify email
                if(form?.newEmail?.length) {
                    const emailRes = await USER_API.verifyEmailOTP(emailOTP)
                    .catch(err => {
                        toast.error("Invalid New Email OTP")
                        return
                    })
                    if(emailRes === false) {
                        toast.error("Invalid New Email OTP")
                        return
                    }
                }

                // verify mobile
                if(form?.newContactNo?.length) {
                    const otpRes = await USER_API.verifyOTP(otp)
                    .catch(err => {
                        toast.error("Invalid New Contact No. OTP")
                        return
                    })
                    if(otpRes === false) {
                        toast.error("Invalid New Contact No. OTP")
                        return
                    }
                }

                const res = await API.submitImage(aadharImg)
                .catch(err => {
                    console.log(err)
                    toast.error("Aadhar Card Image Upload Failed.")
                    return
                })
                if(res && res.fid && res.fid.length > 0){
                    let id = res.fid[0].value.toString();
                    const response = await API.submitFormData({ ...payload, upload_aadhar_card: id })
                    .catch(err => {
                        console.log(err)
                        toast.error("Looks like something went wrong. Please try again later.")
                    })
                    if (response) {
                        setThankYou(true);
                        resetForm();
                        localStorage.removeItem(lockey);
                        localStorage.removeItem(emailkey);
                        window.scroll({ top: 0, behavior: "smooth" })
                    }
                }
            } else {
                toast.error("Please accept the Terms and conditions before proceeding.")
            }	
        }
    }

    const validateUserHHNumber = () => {
        setLoading(true)
        USER_API.validateHHNumber(hhNumber)
        .then(res => {
            if(res) {
                getDetailsHHNumber()
            } else {
                toast.error("Invalid HH Number")
                setLoading(false)
            }
        })
        .catch(err => {
            console.log(err)
            toast.error("Invalid HH Number")
            setLoading(false)
        })
    }

    const getDetailsHHNumber = async () => {
        try {
            const result = await handleHHLogin(hhNumber)
            if(result?.mobileno) {
                setStep2(true)
                setStep3(true)
                setUserFound(result)
                // result['mobileno'] = ''
                localStorage.setItem(lockey, result?.mobileno);
                localStorage.setItem(emailkey, result?.email);
                setForm({
                    ...form,
                    mobile: result?.mobileno,
                    gender: result?.gender,
                    firstName: result?.firstname,
                    lastName: result?.lastname,
                    middleName: result?.midname,
                    email: result?.email,
                    fullName: result?.fullname
                })
                onResendOTP(result?.mobileno)
            } else {
                toast.error("HH Number not associated with mobile number")
            }
            setLoading(false)
        } catch (error) {
            toast.error("Something went wrong")
            console.log(error)
            setLoading(false)
        }
    }
    
    const resetThankyou = (value) => {
        if(window) window.location.reload();
    }

    // useScript("/assets/js/activateAccount.js")
    useScript("/assets/js/custom_select.js"); 
    useScript("/assets/js/login-register.js");

    useEffect(() => {
        if (typeof window.initSelect !== 'undefined') window.initSelect();
        forceUpdateForRegister(4)
    }, [form, step4, step2, step3, loading])

    return (
        <Layout>
            <Meta
                files={{
                    js: [],
                    css: ["/assets/css/login.css"]
                }}
                tags={metaTags}
            />
            <main className="innerpage">
                {thankYou ? <Thankyou setThankYou={resetThankyou} /> : (
                    <section className="section_bg login_section pt-first-section">
                        <div className="container">
                            <h2 className="section-heading">Activate Account</h2>
                            <div className="row no-gutters">
                                <div className="col-lg-12 col-md-12">
                                    <div className="white_rounded_box activate_account">
                                        <div className="form_wrap" id="register_form_wrap">
                                            <div className="form_block">
                                                <p>To activate your profile, please provide your basic information</p>
                                                <div className="row">
                                                    <div className={`col-md-12 ${step4 ? 'col-lg-6' : 'col-lg-4'}`}>
                                                        <div className={`form-group -animated ${step2 || step3 ? '-active select-disabled' : ''}`}>
                                                            <label>HH Number <span className="mandatory">*</span></label>
                                                            <input className="form-control" type="text" value={hhNumber} onChange={(e) => setHHNumber(acceptOnlyNumbers(e?.target?.value))} name="hhNumber" />
                                                            {formValidator.current.message('hhNumber', hhNumber, 'required|min:3|max:10')}
                                                        </div>
                                                    </div>
                                                    {!step4 && step2 || !step4 && step3
                                                        ? <>
                                                            <div className="col-lg-4 col-md-12">
                                                                <div className={`form-group -animated -focus -active ${step3 || step4 ? 'select-disabled' : ''}`}>
                                                                    <label>Mobile No<span className="mandatory">*</span></label>
                                                                    <input className="form-control" type="text" disabled={step3 || step4 ? true : false} value={step3 ? concealMobileNo(form?.mobile) : form?.mobile} onChange={onInputChange} name="mobile" />
                                                                    {/* <a href="javascript:;" className="editMobileNo">Edit Mobile No.</a> */}
                                                                </div>
                                                            </div>
                                                            {step3 &&
                                                                <div className="col-lg-4 col-md-12">
                                                                    <div className="row otp_row">
                                                                        <div className="col-lg-8 col-md-12">
                                                                            <div className="form-group -animated">
                                                                                <label>Enter OTP <img src="/assets/images/icons/icon_info.svg" alt="" /> </label>
                                                                                <input onChange={(e) => setOTP(acceptOnlyNumbers(e.target.value))} className="form-control" type="text" value={otp} />
                                                                                {/* <i className="icon success"></i> */}
                                                                                {step3 && <span className="err_msg">Please enter OTP shared on your mobile number.</span>}
                                                                                {formValidator.current.message('otp', otp, 'required')}
                                                                                {/* <span className="field_note">If OTP not recieved send via SMS or WhatsApp.</span>  */}
                                                                            </div>
                                                                            <LinkButton title={`Resend OTP`} resend onBtnClick={() => onResendOTP()} loading={otpLoading} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                        : null}
                                                    {step4 
                                                    ? <div className="col-lg-6 col-md-12">
                                                        <div className="">
                                                            <div className="row">
                                                                <div className="col-md-3 col-3">
                                                                    <div className="form-group select-dd">
                                                                        <label>Title <span className="mandatory">*</span></label>
                                                                        <select value={form.title} onChange={onInputChange} placeholder={form.title ? form.title : "Select Title"} name="title" id="title" className="form-control custom-select" >
                                                                            {/* <option value="" disabled>Select Title</option> */}
                                                                            <option value="Mr.">Mr.</option>
                                                                            <option value="Miss.">Ms.</option>
                                                                            <option value="Dr.">Dr.</option>
                                                                        </select>
                                                                        {formValidator.current.message('title', form.title, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-9 col-9 disabled_fields_container active">
                                                                    <div className={`form-group -animated ${form?.fullName ? '-active select-disabled' : ''} `}>
                                                                        <label>Full Name <span className="mandatory">*</span></label>
                                                                        <input className="form-control" type="text" value={form?.fullName} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}
                                                </div>
                                                {step4 
                                                ? <div className="active">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className={`form-group -animated ${form?.email ? '-active select-disabled' : ''} `}>
                                                                <label>Email Address <span className="mandatory">*</span></label>
                                                                <input className="form-control" type="text" value={form?.email} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className={`form-group -animated -focus -active select-disabled    `}>
                                                                <label>Mobile No<span className="mandatory">*</span></label>
                                                                <input className="form-control" type="text" disabled={true} value={step3 ? concealMobileNo(form?.mobile) : form?.mobile} onChange={onInputChange} name="mobile" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group -animated">
                                                                <label>New Email Id</label> 
                                                                <input className="form-control" type="text" value={form?.newEmail} name='newEmail' onChange={onInputChange} />
                                                                {!showEmailOtp ? <a href="javascript:;" onClick={() => sendOTPForEmail()} className="editMobileNo otp_color">Verify OTP With Email.</a> : null}
                                                                {formValidator.current.message('newEmail', form?.newEmail, 'email|checkAltEmail')} 
                                                            </div>
                                                        </div>
                                                        {showEmailOtp && form?.newEmail?.length 
                                                        ? <div className="col-lg-6 col-md-12">
                                                            <div className="row otp_row">
                                                                <div className="col-lg-8 col-md-12">
                                                                    <div className="form-group -animated">
                                                                        <label>Enter OTP <img src="/assets/images/icons/icon_info.svg" alt="" /> </label>
                                                                        <input onChange={(e) => setEmailOTP(acceptOnlyNumbers(e.target.value))} className="form-control" type="text" value={emailOTP} />
                                                                        {/* <i className="icon success"></i> */}
                                                                        {emailOTP?.length === 0 ? <span className="err_msg">Please enter OTP shared on your Email Id.</span> : null}
                                                                        {formValidator.current.message('otp', emailOTP, formValidator.current.fields.newEmail ? 'required' : '')}
                                                                    </div>
                                                                    <LinkButton title={`Resend OTP`} resend onBtnClick={() => sendOTPForEmail()} loading={emailLoading} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group -animated">
                                                                <label>New Mobile Number</label>
                                                                <input className="form-control" type="text" value={form?.newContactNo} name='newContactNo' onChange={onInputChange} />
                                                                {!showAltOtp ? <a href="javascript:;" onClick={() => sendOTPForAltPhone()} className="editMobileNo otp_color">Verify OTP With Mobile.</a> : null}
                                                                {formValidator.current.message('newContactNo', form?.newContactNo, 'min:10|max:10|validateMobile|checkAltMobile')}
                                                            </div>
                                                        </div>
                                                        {showAltOtp
                                                        ? <div className="col-lg-6 col-md-12">
                                                            <div className="row otp_row">
                                                                <div className="col-lg-8 col-md-12">
                                                                    <div className="form-group -animated">
                                                                        <label>Enter OTP <img src="/assets/images/icons/icon_info.svg" alt="" /> </label>
                                                                        <input onChange={(e) => setOTP(acceptOnlyNumbers(e.target.value))} className="form-control" type="text" value={otp} />
                                                                        {/* <i className="icon success"></i> */}
                                                                        {otp?.length === 0 ? <span className="err_msg">Please enter OTP shared on your mobile number.</span> : null}
                                                                        {formValidator.current.message('otp', otp, formValidator.current.fields.newContactNo ? 'required' : '')}
                                                                    </div>
                                                                    <LinkButton title={`Resend OTP`} resend onBtnClick={() => onResendOTP(form?.newContactNo, true)} loading={otpLoading} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}
                                                    </div>
                                                    {/* <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group select-dd">
                                                                        <label>Date of Birth <span className="mandatory">*</span></label>
                                                                        <div>
                                                                            <DatePicker 
                                                                                className="form-control"
                                                                                selected={form?.birthDate} 
                                                                                dateFormat="dd/MM/yyyy"
                                                                                showYearDropdown
                                                                                yearDropdownItemNumber={100}
                                                                                scrollableYearDropdown
                                                                                maxDate={new Date()}
                                                                                onChange={async (date) => {
                                                                                    let dob = `${date.getDate() < 10 ? '0'+date.getDate() : date.getDate()}/${date.getMonth() < 9 ? '0'+(Number(date.getMonth())+1) : Number(date.getMonth())+1}/${date.getFullYear()}`
                                                                                    let age = await calculateAge(date.toLocaleDateString())
                                                                                    if(dob?.length) {
                                                                                        setForm({
                                                                                            ...form,
                                                                                            ['dob']: dob,
                                                                                            ['age']: age,
                                                                                            ['birthDate']: date
                                                                                        });
                                                                                    }
                                                                                }} 
                                                                            />
                                                                        </div>
                                                                        {formValidator.current.message('Dob', form.dob, 'required')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group upload-file -animated mb-2">
                                                                <label>Upload Self Attested Identification Proof Image </label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" readonly />
                                                                    <div className="input-group-btn">
                                                                        <span className="fileUpload">
                                                                            <span className="upl upload" id="upload">{aadharImg ? 'Uploaded' : 'Upload'} </span>
                                                                            {aadharImg ? <a className="remove otp_color" id="upload" onClick={() => setAadharImg(null)}>Remove</a> : null}
                                                                            <input name="resume" className="form-control" type="file" onChange={handleAadharImg} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {formValidator.current.message('IdentificationProofImage', aadharImg, 'required')}
                                                            {fileError.length > 0 ? <p className="field_error">{fileError}</p> : ""}
                                                        </div>
                                                    </div>
                                                    {aadharImg?.name 
                                                        ? <div className="notes border-0 mb-2">
                                                            <ul>
                                                                <li>{aadharImg?.name}</li>
                                                            </ul>
                                                        </div>
                                                        : null}
                                                    <div className="row">
                                                        <div className="col-md-10">
                                                            <div className="notes">
                                                                <ul>
                                                                    <li>Make sure that:</li>
                                                                    <li>1. The image is not blurry.</li>
                                                                    <li>2. All the details are clearly visible.</li>
                                                                    <li>3. Image should not exceed 2 MB.</li>
                                                                    <li>4. Only .jpg .png format permitted.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-12">
                                                            <div className="checkbox_container">
                                                                <div className="form-group checkbox-group">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="custom-checkbox">
                                                                                <input defaultChecked={checkbox} onChange={(e) => setCheckbox(e.target.checked)} type="checkbox" name="all_tc" id="all_tc"/>
                                                                                <label htmlFor="all_tc">I accept all the<a target="_blank" href="/terms-and-conditions"> terms & conditions.</a></label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                                
                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                    {step4 
                                                        ? <APIButton onBtnClick={(e) => handleSubmit(e)} title={`Continue`} loading={loading} disabled={loading} className={`btn btn-primary`} />
                                                        :  step3
                                                            ? <APIButton onBtnClick={(e) => handleOTPVerify(e)} title={`Verify OTP`} loading={loading} disabled={loading} className={`btn btn-primary`} />
                                                            : step2
                                                                ? <APIButton onBtnClick={(e) => sendOTP(e)} title={`Send OTP`} loading={loading} disabled={loading} className={`btn btn-primary`} />
                                                                : <APIButton onBtnClick={(e) => handleVerifyHHNumber(e)} title={`Verify HH Number`} loading={loading} disabled={loading} className={`btn btn-primary`} />}
                                                    {/* <button className="btn btn-primary">Continue asdasd</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <p>Kindly contact us at <a href="mailto:info@hindujahospital.com">info@hindujahospital.com</a> if you are unable to proceed with your mobile verification.</p>
                                        </div>
                                        <div className="tankyou_div" style={{display: "none"}}>
                                        <div className="check_mark">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="49.078" height="49.354" viewBox="0 0 49.078 49.354">
                                                <g id="Group_2657" data-name="Group 2657" transform="translate(0)">
                                                    <path id="Path_3040" data-name="Path 3040" d="M52.791,24.176l-1.036-.709A3.209,3.209,0,0,1,50.5,19.924l.327-1.2a3.26,3.26,0,0,0-2.617-4.089l-1.254-.218A3.182,3.182,0,0,1,44.287,11.8l-.218-1.254A3.228,3.228,0,0,0,39.98,7.986l-1.2.382a3.267,3.267,0,0,1-3.543-1.2l-.763-1.09a3.215,3.215,0,0,0-4.8-.491l-.927.872a3.207,3.207,0,0,1-3.761.436L23.9,6.3a3.278,3.278,0,0,0-4.579,1.635l-.491,1.2a3.13,3.13,0,0,1-3.162,2.017l-1.254-.055a3.191,3.191,0,0,0-3.38,3.434l.109,1.254A3.275,3.275,0,0,1,9.18,19l-1.145.491a3.257,3.257,0,0,0-1.581,4.579l.654,1.09a3.207,3.207,0,0,1-.382,3.761l-.818.927a3.181,3.181,0,0,0,.6,4.8l1.036.709A3.209,3.209,0,0,1,8.8,38.9l-.491,1.254a3.26,3.26,0,0,0,2.617,4.089l1.254.218a3.182,3.182,0,0,1,2.671,2.617l.218,1.254a3.228,3.228,0,0,0,4.089,2.562l1.2-.382a3.267,3.267,0,0,1,3.543,1.2l.763,1.036a3.215,3.215,0,0,0,4.8.491l.927-.872a3.207,3.207,0,0,1,3.761-.436l1.09.6a3.278,3.278,0,0,0,4.579-1.635l.491-1.2a3.129,3.129,0,0,1,3.162-2.017l1.254.055a3.191,3.191,0,0,0,3.38-3.434l-.055-1.254a3.275,3.275,0,0,1,1.963-3.216l1.145-.491a3.257,3.257,0,0,0,1.581-4.579l-.654-1.09a3.207,3.207,0,0,1,.382-3.761l.818-.927A3.172,3.172,0,0,0,52.791,24.176Z" transform="translate(-5.081 -4.733)" fill="#33a470"/>
                                                    <g id="Group_2463" data-name="Group 2463" transform="translate(14.719 18.535)">
                                                        <line id="Line_241" data-name="Line 241" x2="6.375" y2="6.375" transform="translate(0 6.375)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4"/>
                                                        <line id="Line_242" data-name="Line 242" y1="12.75" x2="13.388" transform="translate(6.375)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="thankyou_content">
                                            <p>Thank you <span>Krishna Chandaluri</span> for sharing your contact details.</p>
                                            <p>Your HH number is 23234321.</p>
                                        </div>
                                        <div className="btn_wrap">
                                            <button className="btn btn-primary">Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </section>
                )}
            </main>
        </Layout>
    )
}

export default ActivateAccount